import {
  ChartCommentDot,
  ChartDateTickFormat,
  HatchedBackground,
  Loader,
  Tooltip,
} from '@energybox/react-ui-library/dist/components';
import {
  useGraphTouchEvents,
  usePrevious,
} from '@energybox/react-ui-library/dist/hooks';
import { TemperatureLegendIcon as LegendIcon } from '@energybox/react-ui-library/dist/icons';
import { LocalAdjustmentLegendIcon as LocalAdjustmentLegend } from '@energybox/react-ui-library/dist/icons';
import {
  ChartControlProps,
  NewCommentReferenceDot,
  ThermostatWorkingMode,
  WorkingMode,
} from '@energybox/react-ui-library/dist/types';
import {
  classNames,
  DefaultChartStyles,
  getChartDomain,
  getYTicks,
} from '@energybox/react-ui-library/dist/utils';
import React, { useEffect, useState } from 'react';
import {
  Area,
  CartesianGrid,
  ComposedChart,
  Legend,
  Line,
  ReferenceArea,
  ResponsiveContainer,
  Tooltip as RechartsTooltip,
  XAxis,
  YAxis,
  ReferenceDot,
} from 'recharts';
import { useTemperatureChartYRange } from '../../hooks/chartUtils/chartUtils';
import { useTemperatureUnit } from '../../hooks/utils';
import { hatchedLinePatternDef } from '../Charts/Fill';
import { ThermostatActivityProcessedData } from '../EquipmentPageCharts/EquipmentThermostatChart/EquipmentThermostatChart';
import styles from './ThermostatActivityChart.module.css';
import ThermostatActivityChartTooltip from './ThermostatActivityChartTooltip';
import equals from 'ramda/src/equals';
import ChartDisplayCheckboxes from '@energybox/react-ui-library/dist/components/ChartDisplayCheckboxes/ChartDisplayCheckboxes';

interface Props extends ChartControlProps {
  thermostatId: number;
  processedActivityData: ThermostatActivityProcessedData[];
  isLoading: boolean;
  isBarChartShowing: boolean;
  setChartMouseoverTime: React.Dispatch<React.SetStateAction<number>>;
  isCommentModeActive?: boolean;
  comments?: any[];
  newCommentReferenceDot?: NewCommentReferenceDot;
  isNewCommentPopupOpen?: boolean;
  onClickDuringCommentMode?: (e: any) => void;
  unit?: string;
  isVenstarHeatpump?: boolean;
}

const ThermostatActivityChart: React.FC<Props> = ({
  ianaTimeZoneCode,
  processedActivityData,
  isLoading,
  locale,
  fromDate,
  toDate,
  ticks,
  refAreaStart,
  refAreaEnd,
  zoomIn,
  updateRefAreaStart,
  updateRefAreaEnd,
  isBarChartShowing,
  setChartMouseoverTime,
  isCommentModeActive,
  comments,
  newCommentReferenceDot,
  isNewCommentPopupOpen,
  onClickDuringCommentMode,
  unit,
  isVenstarHeatpump,
}) => {
  // *********** Hooks / local vars ************ //
  const temperatureUnit = useTemperatureUnit();
  const { minYData, maxYData, setMinYData, setMaxYData } =
    useTemperatureChartYRange();

  const [showComments, setShowComments] = useState(false);
  const [showEdm, setShowEdm] = useState(false);
  const [showLocalAdjustment, setShowLocalAdjustment] = useState(false);

  const [
    isInitialCommentsLoadingFinished,
    setIsInitialCommentsLoadingFinished,
  ] = useState(false);

  const previousComments = usePrevious(comments);
  const doCommentsExist = comments && comments.length > 0;
  const isAbleToZoom = !isCommentModeActive;

  useEffectSetYRange(
    isLoading,
    processedActivityData,
    setMinYData,
    setMaxYData
  );

  const yDomain = getChartDomain(minYData, maxYData);
  const graphTouchEvents = useGraphTouchEvents(
    true,
    updateRefAreaStart,
    updateRefAreaEnd,
    zoomIn
  );
  const onTouchStart = graphTouchEvents.onTouchStart;
  const onTouchEnd = graphTouchEvents.onTouchEnd;
  const onTouchMove = graphTouchEvents.onTouchMove;

  useEffect(() => {
    if (
      !equals(previousComments, comments) &&
      doCommentsExist &&
      !isInitialCommentsLoadingFinished
    ) {
      setShowComments(true);
      setIsInitialCommentsLoadingFinished(true);
    }
  }, [
    previousComments,
    comments,
    doCommentsExist,
    isInitialCommentsLoadingFinished,
  ]);

  const isDataLoading =
    processedActivityData.length === 0 ||
    processedActivityData.some((obj) => obj.hasOwnProperty('coolSetPoint'));

  const createLinearGradient = (
    id,
    color1,
    color2,
    offset1 = '0%',
    offset2 = '100%',
    opacity1 = 0.4,
    opacity2 = 0
  ) => (
    <linearGradient id={id} x1="0" y1="0" x2="0" y2="1">
      <stop offset={offset1} stopColor={color1} stopOpacity={opacity1} />
      <stop offset={offset2} stopColor={color2} stopOpacity={opacity2} />
    </linearGradient>
  );

  return (
    <div className={styles.root}>
      {(isLoading || !isDataLoading) && (
        <div className={styles.loading}>
          <span>
            {' '}
            <Loader size={40} />{' '}
          </span>
          <h6> Data is loading...</h6>
        </div>
      )}

      {!isLoading && <div className={styles.yAxisLabel}>{temperatureUnit}</div>}

      <ResponsiveContainer height={DefaultChartStyles.chartHeight}>
        <ComposedChart
          throttleDelay={DefaultChartStyles.throttleDelay}
          onClick={onClickDuringCommentMode}
          onMouseDown={
            onTouchStart
              ? isAbleToZoom && onTouchStart
              : (e) => {
                  if (
                    isAbleToZoom &&
                    e &&
                    e.activeLabel &&
                    updateRefAreaStart
                  ) {
                    updateRefAreaStart(e.activeLabel);
                  }
                }
          }
          onMouseMove={
            onTouchMove
              ? isAbleToZoom && onTouchMove
              : (e) => {
                  if (
                    isAbleToZoom &&
                    e &&
                    e.activeLabel &&
                    refAreaStart &&
                    updateRefAreaEnd
                  ) {
                    updateRefAreaEnd(e.activeLabel);
                  }
                }
          }
          onMouseUp={
            onTouchEnd
              ? isAbleToZoom && onTouchEnd
              : () => {
                  if (isAbleToZoom && zoomIn) zoomIn();
                }
          }
        >
          <defs>
            {createLinearGradient(
              'EDMGradient',
              '#2ADDD0',
              '#2ADDD0',
              '10%',
              '100%'
            )}
            {createLinearGradient(
              'CoolingPhaseGradient',
              '#8DD7FE',
              '#8DD7FE',
              '0%',
              '100%',
              1
            )}
            {createLinearGradient(
              'HeatingPhaseGradient',
              '#FFAF7B',
              '#FFAF7B',
              '0%',
              '100%',
              1
            )}
          </defs>

          {hatchedLinePatternDef('hatched_lines_red', 'red', 'pink')}
          {hatchedLinePatternDef('hatched_lines_grey', 'grey', 'white')}

          <CartesianGrid
            vertical={false}
            stroke={DefaultChartStyles.axisLineColor}
          />

          <XAxis
            allowDataOverflow
            axisLine={false}
            tickLine={false}
            dataKey="timestamp"
            type="number"
            domain={[fromDate, toDate]}
            interval={0}
            minTickGap={0}
            ticks={ticks}
            tick={({ x, y, payload }) => {
              return (
                <ChartDateTickFormat
                  ianaTimeZoneCode={ianaTimeZoneCode}
                  x={x}
                  y={y}
                  payload={{
                    ...payload,
                    fromDate,
                    toDate,
                  }}
                  locale={locale}
                />
              );
            }}
          />

          <YAxis
            yAxisId="yAxis"
            dataKey="temperature"
            type="number"
            width={DefaultChartStyles.yAxisWidth}
            axisLine={false}
            tickLine={false}
            domain={yDomain}
            ticks={getYTicks(minYData, maxYData)}
            tick={DefaultChartStyles.tick}
          />

          <Line
            yAxisId="yAxis"
            data={processedActivityData}
            dataKey={({ online, temperature }) => {
              if (!online) return null;
              return temperature;
            }}
            activeDot={isCommentModeActive}
            dot={false}
            isAnimationActive={false}
            type="monotone"
            stroke="var(--turquoise-base)"
            strokeWidth={1.5}
          />
          {/* Local Adjustment Graph Data */}
          <Line
            yAxisId="yAxis"
            data={processedActivityData}
            dataKey={({ timetableActiveState, temperature }) => {
              if (timetableActiveState === 'LOCAL_ADJUSTMENT') {
                return temperature;
              }
              return null;
            }}
            activeDot={isCommentModeActive}
            dot={false}
            isAnimationActive={false}
            type="monotone"
            stroke="#2E8D8B"
            strokeWidth={2}
            strokeOpacity={1}
          />

          {showLocalAdjustment && (
            <Area
              data={processedActivityData}
              yAxisId="yAxis"
              dataKey={({
                timetableActiveState,
                temperature,
                hvacSopSetPointDeviation,
              }) => {
                if (
                  timetableActiveState === 'LOCAL_ADJUSTMENT' &&
                  hvacSopSetPointDeviation > 0
                ) {
                  return temperature;
                }
                return null;
              }}
              fill="url(#HeatingPhaseGradient)"
              activeDot={isCommentModeActive}
              isAnimationActive={false}
              stroke="none"
              fillOpacity="1"
            />
          )}
          {showLocalAdjustment && (
            <Area
              data={processedActivityData}
              yAxisId="yAxis"
              dataKey={({
                timetableActiveState,
                temperature,
                hvacSopSetPointDeviation,
              }) => {
                if (
                  timetableActiveState === 'LOCAL_ADJUSTMENT' &&
                  hvacSopSetPointDeviation < 0
                ) {
                  return temperature;
                }
                return null;
              }}
              fill="url(#CoolingPhaseGradient)"
              activeDot={isCommentModeActive}
              isAnimationActive={false}
              stroke="none"
              fillOpacity="1"
            />
          )}
          {/* End Local Adjustment Graph Data */}
          <Area
            type="stepAfter"
            yAxisId="yAxis"
            data={processedActivityData}
            dataKey={({ online, mode, heatSetPoint, coolSetPoint }) => {
              if (!online || mode === ThermostatWorkingMode.OFF)
                return [null, null];

              if (mode === ThermostatWorkingMode.COOLING) {
                return [coolSetPoint];
              }

              if (mode === ThermostatWorkingMode.HEATING) {
                return [heatSetPoint];
              }

              return [heatSetPoint, coolSetPoint];
            }}
            activeDot={isCommentModeActive}
            isAnimationActive={false}
            stroke="var(--ambient-basePlus25)"
            strokeDasharray="5"
            fill="none"
          />
          {isDataLoading && (
            <Area
              type="stepAfter"
              data={processedActivityData}
              dataKey={({ overrideForcedOnValue }) => {
                //when there is override forceOn, the value will be 0.15
                //this is to keep consistency with actuator charts
                //This refers to the visual of the chart, where
                //the area height will be up to 15% of the total yAxis domain
                const [yDomainMin, yDomainMax] = yDomain;
                const domainRange = yDomainMax - yDomainMin;
                const yAxisValue = domainRange * overrideForcedOnValue;
                return [yDomainMin, yDomainMin + yAxisValue];
              }}
              stroke="transparent"
              fill="var(--indigo-base)"
              fillOpacity="0.4"
              yAxisId="yAxis"
              isAnimationActive={false}
              activeDot={isCommentModeActive}
            />
          )}

          {isDataLoading && (
            <Area
              type="stepAfter"
              data={processedActivityData}
              dataKey={({ overrideForcedOffValue }) => {
                //when there is override forceOff, the value will be 0.15
                //this is to keep consistency with actuator charts
                //This refers to the visual of the chart, where
                //the area height will be up to 15% of the total yAxis domain
                const [yDomainMin, yDomainMax] = yDomain;
                const domainRange = yDomainMax - yDomainMin;
                const yAxisValue = domainRange * overrideForcedOffValue;
                return [yDomainMin, yDomainMin + yAxisValue];
              }}
              stroke="transparent"
              fill="var(--pink-base)"
              fillOpacity="0.4"
              yAxisId="yAxis"
              isAnimationActive={false}
              activeDot={isCommentModeActive}
            />
          )}
          {/* Edm Graph Data */}
          <Line
            yAxisId="yAxis"
            data={processedActivityData}
            dataKey={({ timetableActiveState, temperature }) => {
              if (timetableActiveState === 'EDM_OVERRIDE_ACTIVE') {
                return temperature;
              }
              return null;
            }}
            activeDot={isCommentModeActive}
            dot={false}
            isAnimationActive={false}
            type="monotone"
            stroke="#265661"
            strokeWidth={1.5}
          />
          {showEdm && (
            <Area
              data={processedActivityData}
              yAxisId="yAxis"
              dataKey={({ timetableActiveState, temperature }) => {
                if (timetableActiveState === 'EDM_OVERRIDE_ACTIVE') {
                  return temperature;
                }
                return null;
              }}
              activeDot={isCommentModeActive}
              isAnimationActive={false}
              stroke="none"
              fill="url(#EDMGradient)"
              fillOpacity="1"
            />
          )}
          {/* EDM part Ends */}
          {isDataLoading && (
            <Area
              data={processedActivityData}
              yAxisId="yAxis"
              dataKey={({ workingMode }) => {
                if (workingMode === WorkingMode.CALIBRATION) {
                  const yMax = yDomain[1];
                  return yMax;
                }

                return null;
              }}
              activeDot={isCommentModeActive}
              isAnimationActive={false}
              stroke="none"
              fill="url(#hatched_lines_grey)"
            />
          )}

          {isDataLoading && (
            <Area
              data={processedActivityData}
              yAxisId="yAxis"
              dataKey={({
                online,
                temperature,
                localAdjustmentOverrideEnergybox,
              }) => {
                if (
                  localAdjustmentOverrideEnergybox === false ||
                  online === false
                )
                  return [null, null];

                return temperature;
              }}
              activeDot={isCommentModeActive}
              isAnimationActive={false}
              stroke="none"
              fill="url(#hatched_lines_red)"
            />
          )}

          {isDataLoading && showComments && (comments || []).length > 0 && (
            <Line
              activeDot={false}
              yAxisId="yAxis"
              dataKey="commentValue"
              data={comments}
              strokeWidth={0}
              legendType="none"
              isAnimationActive={false}
              dot={({ cx, cy }) => <ChartCommentDot cx={cx} cy={cy} />}
            />
          )}

          {!isNewCommentPopupOpen && (
            <RechartsTooltip
              isAnimationActive={false}
              content={
                graphTouchEvents.startZoom ? (
                  <div />
                ) : (
                  ({ label, payload }) => {
                    return (
                      <ThermostatActivityChartTooltip
                        ianaTimeZoneCode={ianaTimeZoneCode}
                        label={label}
                        payload={payload}
                        locale={locale}
                        isBarChartShowing={isBarChartShowing}
                        setChartMouseoverTime={setChartMouseoverTime}
                        unit={unit}
                        isVenstarHeatpump={isVenstarHeatpump}
                      />
                    );
                  }
                )
              }
            />
          )}

          <Legend
            align="left"
            iconType="line"
            content={renderLegend(showEdm, showLocalAdjustment)}
            wrapperStyle={DefaultChartStyles.legendContainer}
          />

          {refAreaStart && refAreaEnd ? (
            <ReferenceArea
              yAxisId="yAxis"
              x1={refAreaStart}
              x2={refAreaEnd}
              strokeOpacity={0.3}
            />
          ) : null}

          {newCommentReferenceDot && (
            <ReferenceDot
              isFront
              yAxisId="yAxis"
              x={newCommentReferenceDot.x}
              y={newCommentReferenceDot.y}
              fill="#0095ff"
              stroke="#0095ff"
              r={3}
            />
          )}
        </ComposedChart>
      </ResponsiveContainer>

      <ChartDisplayCheckboxes
        showComments={showComments}
        onClickCommentsCheckbox={() => setShowComments(!showComments)}
        isCommentsCheckboxDisabled={!comments || comments.length === 0}
        isThresholdLinesCheckboxDisabled={true}
        isNotificationsCheckboxDisabled={true}
        hideControlDetailsCheckbox
        showEdm={showEdm}
        showEdmCheckbox
        onClickEdmCheckbox={() => setShowEdm(!showEdm)}
        showLocalAdjustment={showLocalAdjustment}
        showLocalAdjustmentCheckbox
        onClickLocalAdjustmentCheckbox={() =>
          setShowLocalAdjustment(!showLocalAdjustment)
        }
      />
    </div>
  );
};

const useEffectSetYRange = (
  isLoading: boolean,
  activityData: ThermostatActivityProcessedData[],
  setMinYData: React.Dispatch<React.SetStateAction<number>>,
  setMaxYData: React.Dispatch<React.SetStateAction<number>>
) => {
  useEffect(() => {
    if (isLoading || activityData.length === 0) return;
    let min = Number.MAX_SAFE_INTEGER,
      max = Number.MIN_SAFE_INTEGER;
    activityData.forEach((dataPoint) => {
      const { temperature, coolSetPoint, heatSetPoint } = dataPoint;
      const dataTemps = [heatSetPoint, coolSetPoint, temperature];
      const minTemps = [min, ...dataTemps].filter((val) =>
        Number.isFinite(val)
      );
      const maxTemps = [max, ...dataTemps].filter((val) =>
        Number.isFinite(val)
      );
      min = Math.min(...minTemps);
      max = Math.max(...maxTemps);
    });
    if (min === max) {
      min -= 1;
      max += 1;
    }
    // If there is no data these will still be the same, so set them to sane
    // numbers so the graph doesn't range from min safe to max safe
    if (min === Number.MAX_SAFE_INTEGER) {
      min = 0;
    }
    if (max === Number.MIN_SAFE_INTEGER) {
      max = 35;
    }

    setMinYData(min);
    setMaxYData(max);
  }, [setMinYData, setMaxYData, isLoading, activityData]);
};

const renderLegend = (showEdm: boolean, showLocalAdjustment: boolean) => {
  const RedHatchedBackground = HatchedBackground(
    'hatched_icon_red',
    'red',
    'pink'
  );
  const GreyHatchedBackground = HatchedBackground(
    'hatched_icons_grey',
    'grey',
    'white'
  );

  return (
    <div className={styles.legendContainer}>
      <span className={styles.legendItem}>
        <span className={styles.lineIcon}>
          <LegendIcon color="var(--accent-base)" />
        </span>
        <Tooltip
          arrowDirection="top"
          simpleContent={{
            title: 'Temperature',
            description:
              'Space temperature measured by the thermostat’s internal or external sensor(s).',
          }}
        >
          <span>Temperature</span>
        </Tooltip>
      </span>

      <span className={styles.legendItem}>
        <span className={classNames(styles.lineIcon, styles.iconCol)}>
          <LegendIcon
            className={styles.doubleLineIcon}
            color="var(--ambient-basePlus25)"
          />
          <LegendIcon color="var(--ambient-basePlus25)" />
        </span>
        <Tooltip
          arrowDirection="top"
          simpleContent={{
            title: 'Set Points',
            description: `'Heat To' / 'Cool To' set points depending on Thermostat Mode.`,
          }}
        >
          <span>Set Points</span>
        </Tooltip>
      </span>

      <span className={styles.legendItem}>
        <span className={styles.boxIcon}>
          <LegendIcon height={16} color="var(--indigo-base)" />
        </span>
        <Tooltip
          arrowDirection="top"
          simpleContent={{
            title: 'Forced On',
            description:
              'Manual override from platform. Following "Occupied 1" settings.',
          }}
        >
          <span>Forced On</span>
        </Tooltip>
      </span>

      <span className={styles.legendItem}>
        <span className={styles.boxIcon}>
          <LegendIcon height={16} color="var(--pink-base)" />
        </span>
        <Tooltip
          arrowDirection="top"
          simpleContent={{
            title: 'Forced Off',
            description:
              'Manual override from platform. Thermostat switched off.',
          }}
        >
          <span>Forced Off</span>
        </Tooltip>
      </span>

      <span className={styles.legendItem}>
        <span className={styles.boxIcon}>
          <RedHatchedBackground width={20} height={16} />
        </span>
        <Tooltip
          arrowDirection="top"
          simpleContent={{
            title: 'Local Override',
            description:
              'Thermostat settings were adjusted locally on site or externally.',
          }}
        >
          <span>Local Override</span>
        </Tooltip>
      </span>

      <span className={styles.legendItem}>
        <span className={styles.boxIcon}>
          <GreyHatchedBackground width={20} height={16} />
        </span>
        <Tooltip
          arrowDirection="top"
          simpleContent={{
            title: 'Manual Mode',
            description: 'Energybox-Controls not active.',
          }}
        >
          <span>Manual Mode</span>
        </Tooltip>
      </span>

      {showEdm && (
        <span className={styles.legendItem}>
          <span className={styles.boxIcon}>
            <LegendIcon height={16} color="var(--accent-base)" />
          </span>
          <Tooltip
            arrowDirection="top"
            simpleContent={{
              title: 'EDM Active Period',
              description: 'Setpoint override to reduce demand charges',
            }}
          >
            <span>EDM Active Period</span>
          </Tooltip>
        </span>
      )}

      {showLocalAdjustment && (
        <span className={styles.legendItem}>
          <LocalAdjustmentLegend />
          <Tooltip
            arrowDirection="top"
            simpleContent={{
              title: 'Local Adjustment',
              description: 'Limited settings changes by local staff',
            }}
          >
            <span className={styles.legendText}>Local Adjustment</span>
          </Tooltip>
        </span>
      )}
    </div>
  );
};

export default ThermostatActivityChart;
