export const HOME = '/';
export const LOGIN = '/login';
export const LOGOUT = '/logout';
export const PROFILE = '/profile';
export const ORG_SWITCH = '/org-switch';

export const CHANGE_PASSWORD = `/settings/change-password`;
export const NEW_PASSWORD = '/new-password';
export const FORGOT_PASSWORD = '/forgot-password';

export const MAP = '/map';
export const SITES = '/sites';
export const LIST = '/list';
export const OVERVIEW = '/overview';

export const SENSORS_AND_GATEWAYS = '/sensors-gateways';
export const SPACES = '/spaces';
export const EQUIPMENT = '/equipment';

export const TODAY = '/today';
export const INCIDENTS = '/incidents';
export const ACTIVE_INCIDENT_COUNTS = '/active-incident-counts';
export const INCIDENT_COUNTS = '/incident-counts';

export const ORG_EQUIPMENT = EQUIPMENT;
export const ORG_EQUIPMENT_PERFORMANCE = '/equipment-performance';
export const ORG_TEMPERATURE_COMPLIANCE = '/org-temp-compliance';
export const ORG_SITES_ENERGY_CONSUMPTION = '/org-sites-energy-consumption';
export const ORG_CONTROLS_SAVINGS = '/org-controls-savings';
export const ORG_ACTIVE_INCIDENT_COUNTS = `${TODAY}${ACTIVE_INCIDENT_COUNTS}`;

export const HVAC_CONTROLS_OPERATIONS = '/hvac-controls-operations';

export const NOTIFICATIONS = '/notifications';
export const NOTIFICATION_OVERVIEW = `${NOTIFICATIONS}${OVERVIEW}`;
export const NOTIFICATION_LIST = `${NOTIFICATIONS}${LIST}`;

export const DASHBOARD = '/dashboard';
export const DASHBOARD_NOTIFICATIONS = `${DASHBOARD}/${NOTIFICATIONS}`;

export const CONTROLS = '/controls';
export const CONTROLS_SCHEDULE = '/controls/schedule';
export const CONTROLS_SAVINGS = '/controls/savings';
export const CONTROLS_THERMOSTATS = '/controls/thermostats';

export const ANALYTICS = '/analytics';
export const TEMPERATURE_COMPLIANCE = '/temperature-compliance';
export const EQUIPMENT_ENERGY_CONSUMPTION = '/equipment-energy-consumption';

export const TASKS = '/tasks';
export const TEMPERATURE_REVIEW = '/temperature-review';

export const MONITORING_LIST_EQUIPMENT = '/monitoring/equipment';
export const MONITORING_SINGLE_EQUIPMENT = `${MONITORING_LIST_EQUIPMENT}/:equipmentId/:view`;
export const MONITORING_LIST_SITES = '/monitoring/sites';
export const MONITORING_SINGLE_SITE = `${MONITORING_LIST_SITES}/:siteId`;
export const MONITORING_SINGLE_SITE_VIEWS = `${MONITORING_LIST_SITES}/:siteId/:view`;

// *** Site Related Pages *** ///
export const SITE_PAGE = `${SITES}/:siteId`;
export const SITE_DASHBOARD = `${SITES}/:siteId${DASHBOARD}`;
export const SITE_NOTIFICATIONS = `${SITES}/:siteId${NOTIFICATIONS}`;
export const SITE_TASKS = `${SITES}/:siteId${TASKS}`;
export const SITE_NOTIFICATIONS_MY_VIEW = `${SITES}/:siteId${NOTIFICATIONS}/my-view`;
export const SITE_NOTIFICATIONS_OVERVIEW = `${SITES}/:siteId${NOTIFICATIONS}/overview`;
export const SITE_ANALYTICS_TEMPERATURE_COMPLIANCE = `${SITES}/:siteId${ANALYTICS}${TEMPERATURE_COMPLIANCE}`;
export const SITE_ANALYTICS_EQUIPMENT_ENERGY_CONSUMPTION = `${SITES}/:siteId${ANALYTICS}${EQUIPMENT_ENERGY_CONSUMPTION}`;
export const SITE_EQUIPMENT_PAGE = `${SITES}/:siteId${EQUIPMENT}/:equipmentId`;
export const SITE_EQUIPMENT_SENSORS_PAGE = `${SITES}/:siteId${EQUIPMENT}/:equipmentId/sensors`;
export const SITE_SPACE_DETAIL_PAGE = `${SITES}/:siteId${SPACES}/:spaceId`;

export const SITE_EQUIPMENT_LIST_PAGE = `${SITES}/:siteId${EQUIPMENT}`;
export const SITE_SPACES_LIST_PAGE = `${SITES}/:siteId${SPACES}`;
export const SITE_DEVICES_LIST_PAGE = `${SITES}/:siteId${SENSORS_AND_GATEWAYS}`;
export const SITE_CONTROLS_SCHEDULE_PAGE = `${SITES}/:siteId${CONTROLS_SCHEDULE}`;
export const SITE_CONTROLS_SAVINGS_PAGE = `${SITES}/:siteId${CONTROLS_SAVINGS}`;
export const SITE_CONTROLS_THERMOSTATS_PAGE = `${SITES}/:siteId${CONTROLS_THERMOSTATS}`;
export const SITE_INCIDENTS_LIST_PAGE = `${SITES}/:siteId${INCIDENTS}`;

export const SITE_TEMP_REVIEW_PAGE = `${SITES}/:siteId${TASKS}${TEMPERATURE_REVIEW}`;

export const MYDOWNLOADS = '/my-downloads';
export const MY_DOWNLOADS_PAGE = `/my-downloads`;
export const REPORT_CENTRE = '/reports';
export const SUMMARY = '/summary';
export const ANNUAL_ENERGY_CONSUMPTION = `${SUMMARY}/annual-energy-consumption`;
export const ANNUAL_CO2_EMISSION = `${SUMMARY}/annual-co2-emission`;
export const ANNUAL_USAGE_INTENSITY = `${SUMMARY}/annual-usage-intensity`;
export const NOH_VS_OH_ENERGY_CONSUMPTION = `${DASHBOARD}/noh_energy_consumption`;

export const USER_INVITE_EMAIL = '/user-invite-email';

export const OPEN_SOURCE_LICENSE = '/license';
export const PUBLIC_OPEN_SOURCE_LICENSE = '/public/license';
