import { SkeletonDisplayText } from '@energybox/react-ui-library/dist/components';
import { Height, Length } from '@energybox/react-ui-library/dist/types';
import {
  classNames,
  formatNumberString,
} from '@energybox/react-ui-library/dist/utils';
import React from 'react';
import styles from './TableWrapper.module.css';

interface Props {
  isLoading?: boolean;
  header?: string;
  children: React.ReactNode;
  className?: string;
  hideTopBar?: boolean;
}

export const displayCount = (count: number, name: string): string => {
  return `${formatNumberString(count)} ${name}`;
};

const TableWrapper: React.FC<Props> = ({
  isLoading,
  header,
  children,
  className = '',
  hideTopBar = false,
}) => {
  return (
    <div
      className={classNames(
        styles.container,
        className,
        header ? styles.topBar : null
      )}
    >
      <div className={styles.headerWrapper}>
        <div className={styles.header}>
          {isLoading && (
            <SkeletonDisplayText
              height={Height.SMALL}
              possibleLengths={[Length.SHORT]}
            />
          )}
          {!isLoading && <>{header || ''}</>}
        </div>
      </div>
      {children}
    </div>
  );
};

export default TableWrapper;
