import {
  ApiObjectStatusById,
  EquipmentEnergyConsumptionApiData,
  NormalizedOrgControlsSavings,
  NormalizedSiteControlsSavings,
  OrgControlsSavingsApiRes,
  OrgSiteEnergyCompareApiRes,
  OrgSiteEnergyCompareApiResSiteDict,
  SiteControlsSavings,
  SitePeakDemandReportType,
  OperatingHoursEnergyBySiteId,
  ObjectById,
  SiteOperatingHoursEnergy,
  GetTemperatureComplianceByOrgResponse,
  GetTemperatureComplianceBySiteResponse,
} from '@energybox/react-ui-library/dist/types';
import { mapArrayToObject } from '@energybox/react-ui-library/dist/utils';
import assocPath from 'ramda/src/assocPath';
import pathOr from 'ramda/src/pathOr';
import pipe from 'ramda/src/pipe';

import { Actions } from '../actions/analytics';
import { HvacOutlierReport } from '../types/analytics/hvac';
import {
  ControlsSavingsState,
  CoolerRecommendationsState,
  EnergyRecommendationsState,
  EnergyReportState,
  EquipmentTypeEnergyStore,
  HumidityRecommendationsState,
  OrganizationEnergyReport,
  OrganizationEnergyReportState,
  SchedulerReportsForSite,
  SchedulerSavingsState,
  SiteOverridesReportState,
  SitePeakDemandReportState,
  TemperatureCompliance,
  TemperatureRecommendationBySiteId,
  OrganizationCO2Report,
  OrganizationEUIReport,
  OrganizationEnergySummaryReport,
  OrganizationEnergyPdf,
  OrganizationNOHEfficiency,
  MonthlyNOHEfficiency,
  ThermostatComplainceReport,
} from './types/analytics';

export interface Analytics {
  energyReport: EnergyReportState;
  sitePeakDemandReport: SitePeakDemandReportState;
  humidityRecommendations: HumidityRecommendationsState;
  coolerRecommendations: CoolerRecommendationsState;
  energyRecommendations: EnergyRecommendationsState;
  schedulerSavings: SchedulerSavingsState;
  controlsSavings: ControlsSavingsState;
  temperatureCompliance: TemperatureCompliance;
  equipmentTypeEnergy: EquipmentTypeEnergyStore;
  orgEnergyReport: OrganizationEnergyReportState;
  siteOverridesReport: SiteOverridesReportState;
  hvacOutliersReport: {
    bySiteId: ApiObjectStatusById<HvacOutlierReport>;
  };
  equipmentEnergyCompare: {
    isLoading: boolean | undefined;
    data: EquipmentEnergyConsumptionApiData | undefined;
  };
  operatingHoursEnergyBySiteId: OperatingHoursEnergyBySiteId;
  operatingHoursEnergyByDate: ObjectById<SiteOperatingHoursEnergy>;
  temperatureRecommendation: TemperatureRecommendationBySiteId;
  organizationCO2Report: OrganizationCO2Report;
  organizationEUIReport: OrganizationEUIReport;
  organizationEnergySummaryReport: OrganizationEnergySummaryReport;
  organizationEnergyPdf: OrganizationEnergyPdf;
  organizationNonOperatingHour: OrganizationNOHEfficiency;
  monthlyNonOperatingHour: MonthlyNOHEfficiency;
  thermostatComplainceReport: ThermostatComplainceReport;
}

const initialState: Analytics = {
  energyReport: {
    bySiteId: {},
    isLoading: false,
  },
  humidityRecommendations: {
    bySiteId: {},
    isLoading: false,
  },
  coolerRecommendations: {
    bySiteId: {},
    isLoading: false,
  },
  energyRecommendations: {
    bySiteId: {},
    isLoading: false,
  },
  sitePeakDemandReport: {
    bySiteId: {},
    isCurrentMonthLoading: false,
    isTimeFilterLoading: false,
  },
  schedulerSavings: {
    bySiteId: {},
    isLoading: false,
  },
  controlsSavings: {
    data: undefined,
    isLoading: false,
  },
  temperatureCompliance: {
    byOrgId: {},
    isLoadingByOrgId: {},
    bySiteId: {},
    isLoadingBySiteId: {},
  },
  equipmentTypeEnergy: {
    byTypeId: {},
    byGroupId: {},
    isLoading: false,
  },
  orgEnergyReport: {
    bySiteId: {},
    siteTotalKwh: 0,
    isLoading: false,
    hasData: false,
  },
  siteOverridesReport: {
    bySiteId: {},
    isLoading: false,
  },
  hvacOutliersReport: {
    bySiteId: {},
  },
  equipmentEnergyCompare: {
    isLoading: undefined,
    data: undefined,
  },
  operatingHoursEnergyBySiteId: {},
  operatingHoursEnergyByDate: {},
  temperatureRecommendation: {},
  organizationCO2Report: {},
  organizationEUIReport: {},
  organizationEnergySummaryReport: {},
  organizationEnergyPdf: {},
  organizationNonOperatingHour: {},
  monthlyNonOperatingHour: {},
  thermostatComplainceReport: {
    bySite: {
      key: 0,
      report_date: '',
      _id: '',
    },
    isLoading: false,
  },
};

const KWH_PER_M2_TARGET = 500;

const reducer = (state: Analytics = initialState, action: any) => {
  switch (action.type) {
    case Actions.GET_HUMIDITY_RECOMMENDATIONS_LOADING:
      return assocPath(['humidityRecommendations', 'isLoading'], true, state);

    case Actions.GET_HUMIDITY_RECOMMENDATIONS_ERROR:
      return assocPath(['humidityRecommendations', 'isLoading'], false, state);

    case Actions.GET_HUMIDITY_RECOMMENDATIONS_SUCCESS: {
      const { key: siteId, data } = action.payload;
      if (!siteId) return state;
      return pipe(
        assocPath(['humidityRecommendations', 'bySiteId', action.siteId], data),
        assocPath(['humidityRecommendations', 'isLoading'], false)
      )(state);
    }

    case Actions.GET_COOLER_RECOMMENDATIONS_LOADING:
      return assocPath(['coolerRecommendations', 'isLoading'], true, state);

    case Actions.GET_COOLER_RECOMMENDATIONS_ERROR:
      return assocPath(['coolerRecommendations', 'isLoading'], false, state);

    case Actions.GET_COOLER_RECOMMENDATIONS_SUCCESS: {
      const { key: siteId, data } = action.payload;
      if (!siteId) return state;
      return pipe(
        assocPath(['coolerRecommendations', 'bySiteId', action.siteId], data),
        assocPath(['coolerRecommendations', 'isLoading'], false)
      )(state);
    }

    case Actions.GET_ENERGY_RECOMMENDATIONS_LOADING:
      return assocPath(['energyRecommendations', 'isLoading'], true, state);

    case Actions.GET_ENERGY_RECOMMENDATIONS_ERROR:
      return assocPath(['energyRecommendations', 'isLoading'], false, state);

    case Actions.GET_ENERGY_RECOMMENDATIONS_SUCCESS: {
      const { key: siteId, data } = action.payload;
      if (!siteId) return state;
      return pipe(
        assocPath(['energyRecommendations', 'bySiteId', action.siteId], data),
        assocPath(['energyRecommendations', 'isLoading'], false)
      )(state);
    }

    case Actions.GET_ENERGY_REPORT_BY_SITE_ID_SUCCESS: {
      const { key: siteId, data } = action.payload;
      return pipe(
        assocPath(['energyReport', 'bySiteId', siteId], data),
        assocPath(['energyReport', 'isLoading'], false)
      )(state);
    }

    case Actions.GET_ENERGY_REPORT_BY_SITE_ID_LOADING:
      return pipe(assocPath(['energyReport', 'isLoading'], true))(state);

    case Actions.GET_ENERGY_REPORT_BY_SITE_ID_ERROR:
      return pipe(assocPath(['energyReport', 'isLoading'], false))(state);

    case Actions.GET_SITE_PEAK_DEMAND_REPORT_SUCCESS: {
      const { key: siteId, data } = action.payload;
      return pipe(
        assocPath(
          ['sitePeakDemandReport', 'bySiteId', siteId, action.reportType],
          data
        ),
        assocPath(
          [
            'sitePeakDemandReport',
            action.reportType === SitePeakDemandReportType.BY_CURRENT_MONTH
              ? 'isCurrentMonthLoading'
              : 'isTimeFilterLoading',
          ],
          false
        )
      )(state);
    }

    case Actions.GET_SITE_PEAK_DEMAND_REPORT_LOADING:
      return assocPath(
        [
          'sitePeakDemandReport',
          action.reportType === SitePeakDemandReportType.BY_CURRENT_MONTH
            ? 'isCurrentMonthLoading'
            : 'isTimeFilterLoading',
        ],
        true,
        state
      );

    case Actions.GET_SITE_PEAK_DEMAND_REPORT_ERROR:
      return assocPath(
        [
          'sitePeakDemandReport',
          action.reportType === SitePeakDemandReportType.BY_CURRENT_MONTH
            ? 'isCurrentMonthLoading'
            : 'isTimeFilterLoading',
        ],
        false,
        state
      );

    case Actions.GET_SCHEDULER_SAVINGS_BY_SITE_ID_LOADING:
      return assocPath(['schedulerSavings', 'isLoading'], true, state);

    case Actions.GET_SCHEDULER_SAVINGS_BY_SITE_ID_SUCCESS:
      return pipe(
        assocPath(
          ['schedulerSavings', 'bySiteId', action.siteId],
          action.payload.data as SchedulerReportsForSite
        ),
        assocPath(['schedulerSavings', 'isLoading'], false)
      )(state);

    case Actions.GET_CONTROLS_SAVINGS_BY_SITE_ID_LOADING:
      return assocPath(['controlsSavings', 'isLoading'], true, state);

    case Actions.GET_CONTROLS_SAVINGS_BY_SITE_ID_SUCCESS:
      return pipe(
        assocPath(
          ['controlsSavings', 'bySiteId', action.siteId],
          action.payload.data
        ),
        assocPath(['controlsSavings', 'isLoading'], false)
      )(state);

    case Actions.GET_TEMPERATURE_COMPLIANCE_BY_ORG_ID_ERROR:
      return assocPath(
        ['temperatureReports', 'isLoadingByOrgId', action.orgId],
        false,
        state
      );

    case Actions.GET_TEMPERATURE_COMPLIANCE_BY_ORG_ID_LOADING:
      return assocPath(
        ['temperatureReports', 'isLoadingByOrgId', action.orgId],
        true,
        state
      );

    case Actions.GET_TEMPERATURE_COMPLIANCE_BY_ORG_ID_SUCCESS: {
      const { orgId, payload } = action as {
        orgId: string;
        payload: GetTemperatureComplianceByOrgResponse;
      };

      const stateUpdates = [
        assocPath(['temperatureCompliance', 'byOrgId', orgId], payload),
        assocPath(['temperatureCompliance', 'isLoadingByOrgId', orgId], false),
      ];

      return pipe(...stateUpdates)(state);
    }

    case Actions.GET_TEMPERATURE_COMPLIANCE_BY_SITE_ID_ERROR:
      return assocPath(
        ['temperatureReports', 'isLoadingBySiteId', action.siteId],
        false,
        state
      );

    case Actions.GET_TEMPERATURE_COMPLIANCE_BY_SITE_ID_LOADING:
      return assocPath(
        ['temperatureReports', 'isLoadingSiteId', action.siteId],
        true,
        state
      );

    case Actions.GET_TEMPERATURE_COMPLIANCE_BY_SITE_ID_SUCCESS: {
      const { siteId, payload } = action as {
        siteId: string;
        payload: GetTemperatureComplianceBySiteResponse;
      };

      const stateUpdates = [
        assocPath(['temperatureCompliance', 'bySiteId', siteId], payload),
        assocPath(
          ['temperatureCompliance', 'isLoadingBySiteId', siteId],
          false
        ),
      ];

      return pipe(...stateUpdates)(state);
    }

    case Actions.GET_ORG_EQUIPMENT_TYPE_ENERGY_SUCCESS: {
      const { payload } = action;
      const response = pathOr({}, ['data', 'by_equipment_type'], payload);
      const equipmentTypeIds = Object.keys(response);

      const reportsByGroupId = equipmentTypeIds.reduce(
        (result, equipmentTypeId) => {
          const report = response[equipmentTypeId];
          const { type_group_id: groupId } = report;
          const byGroupId = {
            ...result[groupId],
            [equipmentTypeId]: report,
          };
          result[groupId] = byGroupId;
          return result;
        },
        {}
      );

      return pipe(
        assocPath(['equipmentTypeEnergy', 'byTypeId'], response),
        assocPath(['equipmentTypeEnergy', 'byGroupId'], reportsByGroupId),
        assocPath(['equipmentTypeEnergy', 'isLoading'], false)
      )(state);
    }

    case Actions.GET_ORG_EQUIPMENT_TYPE_ENERGY_ERROR:
      return assocPath(['equipmentTypeEnergy', 'isLoading'], false, state);

    case Actions.GET_ORG_EQUIPMENT_TYPE_ENERGY_LOADING:
      return assocPath(['equipmentTypeEnergy', 'isLoading'], true, state);

    case Actions.GET_ORGANIZATION_ENERGY_CONSUMPTION_LOADING:
      return assocPath(['orgEnergyReport', 'isLoading'], true, state);

    case Actions.GET_ORGANIZATION_ENERGY_CONSUMPTION_ERROR:
      return assocPath(['orgEnergyReport', 'isLoading'], false, state);

    case Actions.GET_ORGANIZATION_ENERGY_CONSUMPTION_SUCCESS:
      const { payload }: { payload: OrgSiteEnergyCompareApiRes } = action;
      const hasData = Object.entries(payload.data.site_dict).length > 0;
      const SiteEnergyReports: OrganizationEnergyReport[] = Object.entries(
        payload.data.site_dict as OrgSiteEnergyCompareApiResSiteDict
      ).map<OrganizationEnergyReport>(([siteId, siteUsage]) => {
        return {
          siteId,
          siteTitle: siteUsage.site_title,
          siteKwh: siteUsage.site_kwh,
          kwhPerUnitArea: siteUsage.kwh_per_unit_area,
          numEquipment: siteUsage.number_of_equipment,
          lastMonth: siteUsage.last_month,
          twoMonthsAgo: siteUsage.two_months_ago,
          threeMonthsAgo: siteUsage.three_months_ago,
          inConsumptionPerHour: siteUsage.cph_in_sched,
          outConsumptionPerHour: siteUsage.cph_out_sched,
          inScheduleConsumption: siteUsage.consumption_in_sched,
          outScheduleConsumption: siteUsage.consumption_out_sched,
        };
      });
      const report: OrganizationEnergyReportState = {
        bySiteId: mapArrayToObject(SiteEnergyReports, 'siteId'),
        siteTotalKwh: payload.data.site_total_kwh,
        isLoading: false,
        hasData,
      };
      return pipe(
        assocPath(['orgEnergyReport', 'bySiteId'], report.bySiteId),
        assocPath(['orgEnergyReport', 'siteTotalKwh'], report.siteTotalKwh),
        assocPath(['orgEnergyReport', 'isLoading'], report.isLoading),
        assocPath(['orgEnergyReport', 'hasData'], report.hasData)
      )(state);

    case Actions.GET_SITE_OVERRIDES_REPORT_SUCCESS:
      return pipe(
        assocPath(
          ['siteOverridesReport', 'bySiteId', action.siteId],
          action.payload.data.by_equipment_id
        ),
        assocPath(['siteOverridesReport', 'isLoading'], false)
      )(state);

    case Actions.GET_SITE_OVERRIDES_REPORT_ERROR:
      return pipe(assocPath(['siteOverridesReport', 'isLoading'], false))(
        state
      );

    case Actions.GET_SITE_OVERRIDES_REPORT_LOADING:
      return pipe(assocPath(['siteOverridesReport', 'isLoading'], true))(state);

    case Actions.GET_CONTROLS_SAVINGS_LOADING:
      return assocPath(['controlsSavings', 'isLoading'], true, state);

    case Actions.GET_CONTROLS_SAVINGS_ERROR:
      return assocPath(['controlsSavings', 'isLoading'], false, state);

    case Actions.GET_CONTROLS_SAVINGS_SUCCESS:
      const unprocessedData: OrgControlsSavingsApiRes | undefined = pathOr(
        undefined,
        ['payload', 'data'],
        action
      );
      let normalizedData: NormalizedOrgControlsSavings | undefined;
      if (!unprocessedData) normalizedData = undefined;
      else {
        const siteDataMapping = unprocessedData.by_site;
        const processedSiteData: {
          [site_id: number]: NormalizedSiteControlsSavings;
        } = {};
        Object.keys(siteDataMapping).forEach((site_id) => {
          const siteData: SiteControlsSavings = siteDataMapping[site_id];
          const controlsEquipmentCount = Object.keys(
            siteData.by_equipment
          ).length;
          processedSiteData[site_id] = {
            ...siteData,
            site_id: +site_id,
            controlsEquipmentCount,
          };
        });
        normalizedData = {
          ...unprocessedData,
          by_site: processedSiteData,
        };
      }

      return pipe(
        assocPath(['controlsSavings', 'data'], normalizedData),
        assocPath(['controlsSavings', 'isLoading'], false)
      )(state);

    case Actions.GET_HVAC_OUTLIERS_BY_SITE_ID_LOADING:
      return assocPath(
        ['hvacOutliersReport', 'bySiteId', action.siteId, 'isLoading'],
        true,
        state
      );

    case Actions.GET_HVAC_OUTLIERS_BY_SITE_ID_SUCCESS:
      return pipe(
        assocPath(
          ['hvacOutliersReport', 'bySiteId', action.siteId, 'data'],
          action.payload.data
        ),
        assocPath(
          ['hvacOutliersReport', 'bySiteId', action.siteId, 'isLoading'],
          false
        )
      )(state);

    case Actions.GET_HVAC_OUTLIERS_BY_SITE_ID_LOADING:
      return assocPath(
        ['hvacOutliersReport', 'bySiteId', action.siteId, 'isLoading'],
        true,
        state
      );

    case Actions.GET_EQUIPMENT_ENERGY_COMPARE_LOADING:
      return pipe(assocPath(['equipmentEnergyCompare', 'isLoading'], true))(
        state
      );

    case Actions.GET_EQUIPMENT_ENERGY_COMPARE_SUCCESS:
      return pipe(
        assocPath(['equipmentEnergyCompare', 'isLoading'], false),
        assocPath(
          ['equipmentEnergyCompare', 'data'],
          action.payload.data.equipment_dict
        )
      )(state);

    case Actions.GET_SITE_OPERATING_HOURS_ENERGY_SUCCESS:
      if (action.dateKey) {
        return assocPath(
          ['operatingHoursEnergyByDate', action.dateKey],
          {
            isLoading: false,
            data: action.payload.data,
          },
          state
        );
      }
      return assocPath(
        ['operatingHoursEnergyBySiteId', action.siteId],
        {
          isLoading: false,
          data: action.payload.data,
        },
        state
      );

    case Actions.GET_SITE_OPERATING_HOURS_ENERGY_LOADING:
      if (action.dateKey) {
        return assocPath(
          ['operatingHoursEnergyByDate', action.dateKey, 'isLoading'],
          true,
          state
        );
      }
      return assocPath(
        ['operatingHoursEnergyBySiteId', action.siteId, 'isLoading'],
        true,
        state
      );

    case Actions.GET_SITE_OPERATING_HOURS_ENERGY_ERROR:
      if (action.dateKey) {
        return assocPath(
          ['operatingHoursEnergyByDate', action.dateKey, 'isLoading'],
          false,
          state
        );
      }
      return assocPath(
        ['operatingHoursEnergyBySiteId', action.siteId, 'isLoading'],
        false,
        state
      );

    case Actions.GET_TEMPERATURE_RECOMMENDATIONS_BY_SITE_LOADING:
      return assocPath(
        ['temperatureRecommendation', action.siteId, 'isLoading'],
        true,
        state
      );

    case Actions.GET_TEMPERATURE_RECOMMENDATIONS_BY_SITE_SUCCESS:
      return assocPath(
        ['temperatureRecommendation', action.siteId],
        {
          isLoading: false,
          data: action.payload.data,
        },
        state
      );

    case Actions.GET_TEMPERATURE_RECOMMENDATIONS_BY_SITE_ERROR:
      return assocPath(
        ['temperatureRecommendation', action.siteId, 'isLoading'],
        false,
        state
      );

    case Actions.START_ORGANIZATION_CO2_REPORT_LOADING:
      return assocPath(
        ['organizationCO2Report', action.organizationId, 'isLoading'],
        true,
        state
      );

    case Actions.START_ORGANIZATION_CO2_REPORT_SUCCESS:
      return assocPath(
        ['organizationCO2Report', action.organizationId],
        {
          isLoading: true,
          reportKey: action.payload.report_key,
        },
        state
      );

    case Actions.START_ORGANIZATION_CO2_REPORT_ERROR:
      return assocPath(
        ['organizationCO2Report', action.organizationId, 'isLoading'],
        false,
        state
      );

    case Actions.GET_ORGANIZATION_CO2_REPORT_LOADING:
      return assocPath(
        ['organizationCO2Report', action.organizationId, 'isLoading'],
        true,
        state
      );

    case Actions.GET_ORGANIZATION_CO2_REPORT_SUCCESS:
      const { data: co2ReportData, report_version: co2ReportVersion } =
        action.payload;
      const co2ReportKey = co2ReportVersion < 1 ? action.timestamp : undefined;
      return assocPath(
        ['organizationCO2Report', action.organizationId],
        {
          isLoading: co2ReportVersion < 1,
          data: co2ReportData,
          reportVersion: co2ReportVersion,
          reportFetchTime: new Date(),
          reportKey: co2ReportKey,
        },
        state
      );

    case Actions.GET_ORGANIZATION_CO2_REPORT_ERROR:
      return assocPath(
        ['organizationCO2Report', action.organizationId, 'isLoading'],
        false,
        state
      );

    case Actions.START_ORGANIZATION_EUI_REPORT_LOADING:
      return assocPath(
        ['organizationEUIReport', action.organizationId, 'isLoading'],
        true,
        state
      );

    case Actions.START_ORGANIZATION_EUI_REPORT_SUCCESS:
      return assocPath(
        ['organizationEUIReport', action.organizationId],
        {
          isLoading: true,
          reportKey: action.payload.report_key,
        },
        state
      );

    case Actions.START_ORGANIZATION_EUI_REPORT_ERROR:
      return assocPath(
        ['organizationEUIReport', action.organizationId, 'isLoading'],
        false,
        state
      );

    case Actions.GET_ORGANIZATION_EUI_REPORT_LOADING:
      return assocPath(
        ['organizationEUIReport', action.organizationId, 'isLoading'],
        true,
        state
      );

    case Actions.GET_ORGANIZATION_EUI_REPORT_SUCCESS:
      const { data: euiReportData, report_version: euiReportVersion } =
        action.payload;
      const euiReportKey = euiReportVersion < 1 ? action.timestamp : undefined;
      return assocPath(
        ['organizationEUIReport', action.organizationId],
        {
          isLoading: euiReportVersion < 1,
          data: euiReportData,
          reportVersion: euiReportVersion,
          reportFetchTime: new Date(),
          reportKey: euiReportKey,
          targetLevel: KWH_PER_M2_TARGET,
        },
        state
      );

    case Actions.GET_ORGANIZATION_EUI_REPORT_ERROR:
      return assocPath(
        ['organizationEUIReport', action.organizationId, 'isLoading'],
        false,
        state
      );

    case Actions.START_ORGANIZATION_ENERGY_SUMMARY_REPORT_LOADING:
      return assocPath(
        ['organizationEnergySummaryReport', action.organizationId, 'isLoading'],
        true,
        state
      );

    case Actions.START_ORGANIZATION_ENERGY_SUMMARY_REPORT_SUCCESS:
      return assocPath(
        ['organizationEnergySummaryReport', action.organizationId],
        {
          isLoading: true,
          reportKey: action.payload.report_key,
        },
        state
      );

    case Actions.START_ORGANIZATION_ENERGY_SUMMARY_REPORT_ERROR:
      return assocPath(
        ['organizationEnergySummaryReport', action.organizationId, 'isLoading'],
        false,
        state
      );

    case Actions.GET_ORGANIZATION_ENERGY_SUMMARY_REPORT_LOADING:
      return assocPath(
        ['organizationEnergySummaryReport', action.organizationId, 'isLoading'],
        true,
        state
      );

    case Actions.GET_ORGANIZATION_ENERGY_SUMMARY_REPORT_SUCCESS:
      const {
        data: energySummaryReportData,
        report_version: energySummaryReportVersion,
      } = action.payload;
      const energySummaryReportKey =
        energySummaryReportVersion < 1 ? action.timestamp : undefined;
      return assocPath(
        ['organizationEnergySummaryReport', action.organizationId],
        {
          isLoading: energySummaryReportVersion < 1,
          data: energySummaryReportData,
          reportVersion: energySummaryReportVersion,
          reportFetchTime: new Date(),
          reportKey: energySummaryReportKey,
        },
        state
      );

    case Actions.GET_ORGANIZATION_ENERGY_SUMMARY_REPORT_ERROR:
      return assocPath(
        ['organizationEnergySummaryReport', action.organizationId, 'isLoading'],
        false,
        state
      );

    case Actions.START_ORGANIZATION_ENERGY_PDF_LOADING:
      return assocPath(
        ['organizationEnergyPdf', action.organizationId, 'isLoading'],
        true,
        state
      );

    case Actions.START_ORGANIZATION_ENERGY_PDF_SUCCESS:
      return assocPath(
        ['organizationEnergyPdf', action.organizationId],
        {
          isLoading: true,
          reportKey: action.payload.report_key,
        },
        state
      );

    case Actions.START_ORGANIZATION_ENERGY_PDF_ERROR:
      return assocPath(
        ['organizationEnergyPdf', action.organizationId, 'isLoading'],
        false,
        state
      );

    case Actions.GET_ORGANIZATION_ENERGY_PDF_LOADING:
      return assocPath(
        ['organizationEnergyPdf', action.organizationId, 'isLoading'],
        true,
        state
      );

    case Actions.GET_ORGANIZATION_ENERGY_PDF_SUCCESS:
      const {
        data: energyPdfReportData,
        report_version: energyPdfReportVersion,
      } = action.payload;
      const energyPdfReportKey =
        energyPdfReportVersion < 1 ? action.timestamp : undefined;
      return assocPath(
        ['organizationEnergyPdf', action.organizationId],
        {
          isLoading: energyPdfReportVersion < 1,
          data: energyPdfReportData,
          reportVersion: energyPdfReportVersion,
          reportFetchTime: new Date(),
          reportKey: energyPdfReportKey,
          targetLevel: KWH_PER_M2_TARGET,
        },
        state
      );

    case Actions.GET_ORGANIZATION_ENERGY_PDF_ERROR:
      return assocPath(
        ['organizationEnergyPdf', action.organizationId, 'isLoading'],
        false,
        state
      );

    case Actions.START_ORG_NOH_LOADING:
      return assocPath(
        ['organizationNonOperatingHour', action.organizationId, 'isLoading'],
        true,
        state
      );

    case Actions.START_ORG_NOH_SUCCESS:
      return assocPath(
        ['organizationNonOperatingHour', action.organizationId],
        {
          isLoading: true,
          reportKey: action.payload.report_key,
        },
        state
      );

    case Actions.START_ORG_NOH_ERROR:
      return assocPath(
        ['organizationNonOperatingHour', action.organizationId, 'isLoading'],
        false,
        state
      );

    case Actions.GET_ORG_NOH_LOADING:
      return assocPath(
        ['organizationNonOperatingHour', action.organizationId, 'isLoading'],
        true,
        state
      );

    case Actions.GET_ORG_NOH_SUCCESS:
      const { data: orgNOHReportData, report_version: orgNOHReportVersion } =
        action.payload;
      const orgNOHReportKey =
        orgNOHReportVersion < 1 ? action.timestamp : undefined;
      return assocPath(
        ['organizationNonOperatingHour', action.organizationId],
        {
          isLoading: orgNOHReportVersion < 1,
          data: orgNOHReportData,
          reportVersion: orgNOHReportVersion,
          reportFetchTime: new Date(),
          reportKey: orgNOHReportKey,
        },
        state
      );

    case Actions.GET_ORG_NOH_ERROR:
      return assocPath(
        ['organizationNonOperatingHour', action.organizationId, 'isLoading'],
        false,
        state
      );

    case Actions.START_MONTHLY_NOH_LOADING:
      return assocPath(
        ['monthlyNonOperatingHour', action.organizationId, 'isLoading'],
        true,
        state
      );

    case Actions.START_MONTHLY_NOH_SUCCESS:
      return assocPath(
        ['monthlyNonOperatingHour', action.organizationId],
        {
          isLoading: true,
          reportKey: action.payload.report_key,
        },
        state
      );

    case Actions.START_MONTHLY_NOH_ERROR:
      return assocPath(
        ['monthlyNonOperatingHour', action.organizationId, 'isLoading'],
        false,
        state
      );

    case Actions.GET_MONTHLY_NOH_LOADING:
      return assocPath(
        ['monthlyNonOperatingHour', action.organizationId, 'isLoading'],
        true,
        state
      );

    case Actions.GET_MONTHLY_NOH_SUCCESS:
      const {
        data: monthlyNOHReportData,
        report_version: monthlyNOHReportVersion,
      } = action.payload;
      const monthlyNOHReportKey =
        monthlyNOHReportVersion < 1 ? action.timestamp : undefined;
      return assocPath(
        ['monthlyNonOperatingHour', action.organizationId],
        {
          isLoading: monthlyNOHReportVersion < 1,
          data: monthlyNOHReportData,
          reportVersion: monthlyNOHReportVersion,
          reportFetchTime: new Date(),
          reportKey: monthlyNOHReportKey,
        },
        state
      );

    case Actions.GET_MONTHLY_NOH_ERROR:
      return assocPath(
        ['monthlyNonOperatingHour', action.organizationId, 'isLoading'],
        false,
        state
      );

    case Actions.GET_THERMOSTAT_COMPLAIANCE_REPORT_SUCCESS: {
      const { key: siteId, data } = action.payload;
      return pipe(
        assocPath(['thermostatComplainceReport', 'bySite', siteId], data),
        assocPath(['thermostatComplainceReport', 'isLoading'], false)
      )(state);
    }

    case Actions.GET_THERMOSTAT_COMPLAIANCE_REPORT_LOADING:
      return pipe(assocPath(['thermostatComplainceReport', 'isLoading'], true))(
        state
      );

    case Actions.GET_THERMOSTAT_COMPLAIANCE_REPORT_ERROR:
      return pipe(
        assocPath(['thermostatComplainceReport', 'isLoading'], false)
      )(state);

    default:
      return state;
  }
};

export default reducer;
export * from './types/analytics';
